import React from "react";
import { graphql, Link as GatsbyLink } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Img from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import RichText from "../components/richText";
import { Container } from "../components/container";
import { SiteTitle } from "../components/pageTitle";
import styled from "styled-components";
import { transparentize } from "polished";
import { Button } from "../components/button";

const IndexPage = ({ data }) => {
  const { pageContent } = data;
  return (
    <Layout
      isHome
      hero={
        <Container css="position: relative; display: flex; flex-direction: column; flex-grow: 1; min-height: 100%">
          <SiteTitle id="mainContent">{pageContent.title}</SiteTitle>
          <ImageContainer>
            <Img fluid={pageContent.image.fluid} />
          </ImageContainer>
          <MaxWidthBox>
            <RichText>
              {documentToReactComponents(pageContent.body.json)}
            </RichText>
            <Ctas>
              <Link as={GatsbyLink} isSecondary to="/services/">
                View our services
              </Link>
              <Link as={GatsbyLink} to="/contact/">
                Get in touch
              </Link>
            </Ctas>
          </MaxWidthBox>
        </Container>
      }
    >
      <SEO title="Home" />
    </Layout>
  );
};

const ImageContainer = styled.div`
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 8rem;
    z-index: 1;
    background-image: linear-gradient(
      ${({ theme }) => theme.palette.white},
      ${({ theme }) => transparentize(1, theme.palette.white)}
    );
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0%;
    left: 0;
    z-index: 1;
    background-color: ${({ theme }) =>
    transparentize(0.75, theme.palette.white)};
  }
`;

const MaxWidthBox = styled.div`
  position: relative;
  margin-top: 2rem;
  padding-bottom: 12rem;

  @media (min-width: 32em) {
    padding-bottom: 18rem;
  }

  @media (min-width: 60em) {
    margin-right: 6rem;
  }

  @media (min-width: 90em) {
    max-width: 40%;
  }
`;

const Ctas = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
`;

const Link = styled(Button)`
  margin-top: 1rem;
  margin-right: 1rem;
  text-decoration: none;
`;

export const pageQuery = graphql`
  query HomePage {
    pageContent: contentfulPage(
      contentful_id: { eq: "22UginVm3HoNsgHBhdTTKo" }
    ) {
      id
      title
      body {
        json
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`;

export default IndexPage;
